import React from 'react';

import * as styles from './EmptyState.module.scss';

function EmptyState({title, children}) {
  return (
    <div className={styles.Container}>
      <h1>{title}</h1>
      <div>{children}</div>
    </div>
  );
}

export default EmptyState;
