import React from 'react';
import Page from '../components/Page';
import EmptyState from '../components/EmptyState';
import {Link} from 'gatsby';

const Fallback = ({location: {pathname}}) => {
  if (pathname.includes('/category/')) {
    return (
      <Page
        title="Category not found"
        description="Category not found"
        path={pathname}
      >
        <EmptyState title="Category not found">
          <div>
            Oops, the category you're looking for cannot be found. It's possible
            the feeds no longer have articles in this category.
          </div>
          <div>
            <Link id={pathname} to="/categories">
              View current categories
            </Link>
          </div>
        </EmptyState>
      </Page>
    );
  }
  return (
    <Page title="Page not found" description="Page not found" path="404">
      <EmptyState title="Page not found">
        <div>Oops, the page you're looking for cannot be found.</div>
        <div>
          <Link id="popular" to="/">
            View articles
          </Link>
        </div>
      </EmptyState>
    </Page>
  );
};

export default Fallback;
